import { ButtonPrimary } from 'src/components/ui-components/Button';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useCallback, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetRPSignalRConfig,
} from 'src/apis/resourcePlannerAPI';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ToastItem } from 'src/components/ToastItem';
import { signalRConnectionIdKey } from '../sessionStorageKeys';

export const useSignalRConnection = () => {
  const qc = useQueryClient();
  const { t } = useTranslation('resourcePlanner');

  const { tenantId, serviceUrl } = useGetRPSignalRConfig();

  const reloadResourcePlanner = useCallback(() => {
    qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
    qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
    toast.dismiss('reloadRPToast');
  }, [qc]);

  const hubConnectionRef = useRef<HubConnection>();

  useEffect(() => {
    (async () => {
      try {
        hubConnectionRef.current = new HubConnectionBuilder()
          .withUrl(serviceUrl)
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        // Starts the SignalR connection
        await hubConnectionRef.current.start().catch((error) => {
          // eslint-disable-next-line
          console.log(error);

          toast.update('rpIsLoadingToast', {
            render: <ToastItem message="Error in connection. Try again" />,
            type: toast.TYPE.ERROR,
          });
        });

        // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
        if (hubConnectionRef.current.connectionId) {
          sessionStorage.setItem(signalRConnectionIdKey, hubConnectionRef.current.connectionId);
          hubConnectionRef.current.invoke('SubscribeToTenantId', tenantId);
        }

        hubConnectionRef.current.on('ReloadPlanWithMessage', () => {
          toast.info(
            <ToastItem
              message={t('NewChangesDetected')}
              actions={
                <ButtonPrimary
                  size="tiny"
                  onClick={reloadResourcePlanner}
                  data-automation-id="Reload"
                >
                  {t('Reload')}
                </ButtonPrimary>
              }
            />,
            { toastId: 'reloadRPToast' },
          );
        });

        hubConnectionRef.current.on('ReloadPlan', () => {
          qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
          qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
          qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
        });
      } catch {
        // catch error
      }
    })();
    return function cleanup() {
      hubConnectionRef.current?.stop();
      qc.removeQueries([GROUP_BY_RESOURCE_KEY]);
      qc.removeQueries([GROUP_BY_PROJECT_KEY]);
      qc.removeQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
      toast.dismiss('reloadRPToast');
    };
  }, [serviceUrl, tenantId, qc, reloadResourcePlanner, t]);
};
