import { Suspense } from 'react';
import MainSearchStoreProvider from 'src/stores/MainSearchStore';
import MobileNavigationStoreProvider from 'src/stores/MobileNavigationStore';
import PageStoreProvider from 'src/stores/PageStore';
import LoginCallbackRedirection from 'src/components/LoginCallbackRedirection';
import DesignSevenVerification from 'src/components/DesignSevenVerification';
import UserLanguage from 'src/components/UserLanguage';
import RefetchControl from 'src/components/RefetchControl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import { Spinner } from 'src/components/ui-components/Spinner';
import { LocaleProvider } from 'src/components/ui-components/LocaleProvider';
import { LinkProvider } from 'src/components/ui-components/LinkProvider';
import MainRouter from 'src/routers/MainRouter';
import DesignSevenReroute from 'src/routers/RedirectDesignSeven';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isDev } from 'src/utils/env';
import { DefaultLink } from 'src/components/DefaultLink';
import styles from './App.module.scss';
import { useGetSiteLocale } from './apis/indexAPI';
import { ToastContainer } from './components/ToastContainer';
import { ShortcutSystem } from './ShortcutSystem';

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: true,
      },
    },
  });

  const useGetLocale = () => {
    const { siteLocale } = useGetSiteLocale();

    return siteLocale || 'en-GB';
  };

  return (
    <LocaleProvider value={{ getLocale: useGetLocale }}>
      <LinkProvider value={DefaultLink}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <Suspense
              fallback={
                <div className={styles.Spinner} data-automation-id="GlobalSpinner">
                  <Spinner />
                </div>
              }
            >
              <MobileNavigationStoreProvider>
                <PageStoreProvider>
                  <MainSearchStoreProvider>
                    <ShortcutSystem />
                    <RefetchControl />
                    <LoginCallbackRedirection />
                    <DesignSevenVerification />
                    <UserLanguage />
                    <MainRouter />
                    <DesignSevenReroute />
                    <ToastContainer />
                  </MainSearchStoreProvider>
                </PageStoreProvider>
              </MobileNavigationStoreProvider>
            </Suspense>
            {isDev && <ReactQueryDevtools />}
          </QueryClientProvider>
        </StyledEngineProvider>
      </LinkProvider>
    </LocaleProvider>
  );
};

export default App;
