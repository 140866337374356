import Cookies from 'js-cookie';
import { API_URL, APP_URL, MSW_STATUS } from 'src/consts/cookies';
import { loginPortalUrl } from 'src/utils/url';
import { toCamel } from 'src/utils/object';
import {
  GetFetchProps,
  PostFetchProps,
  DeleteFetchProps,
  GenerateUrlProps,
  PutFetchProps,
} from './type';
import {
  BusinessRuleViolation,
  BusinessRuleViolationError,
  TlpErrorResponse,
} from '../errors/errorBase';

const responseHandling = async (response: Response) => {
  const { status, statusText, url } = response;
  const data = await response.json();
  if (response.ok) {
    return data !== null ? toCamel(data) : null;
  }

  // Business Rule Violation (from existing Timelog API standards, to be upgraded in the future)
  if (status === 500 && data.Code === 102) {
    const errorResponse: TlpErrorResponse = toCamel(data);
    const violations: BusinessRuleViolation[] = errorResponse.details.map((violation) => ({
      errorCode: violation.errorCode,
      violationDetails: violation.message,
    }));

    throw new BusinessRuleViolationError('A business rule was violated.', violations);
  }

  if (status === 401) {
    window.location.href = loginPortalUrl;
    throw Error(`${url} Status: ${status} (${statusText})`);
  }
  throw Error(`${url} Status: ${status} (${statusText})`);
};

const responseXMLHandling = async (response: Response) => {
  const { status, statusText, url } = response;
  const data = await response.text();
  const parsedText = await new window.DOMParser().parseFromString(data, 'text/xml');
  if (response.ok) {
    return parsedText || '';
  }

  throw Error(`${url} Status: ${status} (${statusText})`);
};

const getMSWStatus = (key: string) => {
  const mswStatus = JSON.parse(Cookies.get(MSW_STATUS) || '{}');

  return mswStatus?.[key] ? `status=${mswStatus?.[key]}` : '';
};

export const generateUrl = ({ path, key, itemId = '' }: GenerateUrlProps) => {
  const host = Cookies.get(API_URL) || Cookies.get(APP_URL);
  const customStatusParam = getMSWStatus(key);
  const fullUrl = host + path + itemId;
  const joinQuery = fullUrl.indexOf('?') > 0 ? '&' : '?';

  return `${fullUrl}${customStatusParam ? joinQuery : ''}${customStatusParam}`;
};

export const responseHandlingForTest = responseHandling;
export const getMSWStatusForTest = getMSWStatus;
export const generateUrlForTest = generateUrl;

export const getFetch = async ({ path, key, responseType = 'json' }: GetFetchProps) => {
  const apiUrl = generateUrl({ path, key });
  const response = await fetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
  });

  if (responseType === 'xml') return responseXMLHandling(response);

  return responseHandling(response);
};

export const postFetch = async ({ path, key, body, ...restProps }: PostFetchProps) => {
  const response = await fetch(generateUrl({ path, key }), {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
    ...restProps,
  });

  return responseHandling(response);
};

export const putFetch = async ({ path, key, body, ...restProps }: PutFetchProps) => {
  const response = await fetch(generateUrl({ path, key }), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
    ...restProps,
  });

  return responseHandling(response);
};

export const deleteFetch = async ({ path, key, body, itemId }: DeleteFetchProps) => {
  const response = await fetch(generateUrl({ path, key, itemId }), {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  return responseHandling(response);
};
