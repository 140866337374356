import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageDispatch } from 'src/stores/PageStore';
import LegacyPage from 'src/components/LegacyPage';
import { useVerifyDesignSevenPage } from 'src/apis/pagesAPI/designSevenAPI';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';

interface PageRouteProps {
  pageId: string;
  pageIdentifier: string;
  pageTitle: string;
  children: JSX.Element;
}

export default ({ children, pageIdentifier, pageId, pageTitle }: PageRouteProps) => {
  const { isDesignSeven, isLoading } = useVerifyDesignSevenPage(pageIdentifier);
  const pageDispatch = usePageDispatch();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('screenHeaderTitle');

  useEffect(() => {
    if (pageDispatch) {
      pageDispatch({
        type: 'SET_CURRENT_PAGE',
        payload: { pageIdentifier, pageId, pageTitle: translationAnyText(t, pageTitle) },
      });
      pageDispatch({
        type: 'SET_LAST_VISITED_PAGE',
        payload: { pagePath: pathname + search, pageIdentifier },
      });
    }
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return isDesignSeven ? children : <LegacyPage data-automation-id="FallBackLegacyPage" />;
};
