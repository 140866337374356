import { useRef, useState, FC, HtmlHTMLAttributes, useEffect } from 'react';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { useQueryClient } from '@tanstack/react-query';
import { useGetServerTimeZoneId } from 'src/apis/indexAPI';
import { GET_TIME_TRACKER_KEY } from 'src/apis/timeTrackerAPI/utils';
import { useGetTimeTracker } from 'src/apis/timeTrackerAPI/active';
import { Timespan } from './TimeTrackerButton.d';
import styles from './TimeTrackerButton.module.scss';
import { useKeyboardControl, useTimeCountDown } from './timeTrackerHookHelper';
import TimeTrackerTooltipButton from './components/TimeTrackerTooltipButton';
import TimeTrackerPanel from './components/TimeTrackerPanel';

export const TimeTrackerTooltipButtonForTest = TimeTrackerTooltipButton;

export const TimeTrackerButton: FC<HtmlHTMLAttributes<HTMLButtonElement>> = (props) => {
  const TimeTrackerRef = useRef<HTMLDivElement>(null);

  const {
    data: timeTrackerData,
    time,
    details,
    stopPath,
    error: timeTrackerError,
    isLoading,
  } = useGetTimeTracker();
  const { serverTimeZoneId } = useGetServerTimeZoneId();
  const qc = useQueryClient();

  const [isActive, setIsActive] = useState(false);
  const [timeSpan, setTimeSpan] = useState<Timespan | undefined>();

  const handleChangeActive = () =>
    setIsActive((prev) => {
      qc.invalidateQueries([GET_TIME_TRACKER_KEY]);
      return !prev;
    });
  const getTabIndex = () => (isActive ? 0 : undefined);
  const handleClickOutside = () => setIsActive(false);

  useOnClickOutside(TimeTrackerRef, handleClickOutside);
  useKeyboardControl(isActive, setIsActive, TimeTrackerRef?.current);
  useEffect(() => {
    if (!timeSpan && time) setTimeSpan(time);
  }, [time, timeSpan]);
  useTimeCountDown(timeTrackerData, serverTimeZoneId, setTimeSpan);
  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      if (event?.data?.eventId === 'timeTrackerUpdated')
        qc.invalidateQueries([GET_TIME_TRACKER_KEY]);
    };
    window.addEventListener('message', handleIframeEvent as EventListener);
    return () => {
      window.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [qc]);

  if (isLoading || timeTrackerError) {
    return null;
  }
  return (
    <div
      className={styles.Wrapper}
      ref={TimeTrackerRef}
      data-automation-id="TimeTracker"
      tabIndex={getTabIndex()}
    >
      <TimeTrackerTooltipButton
        elementProps={props}
        isActive={isActive}
        handleChangeActive={handleChangeActive}
        timespan={timeSpan}
      />
      <TimeTrackerPanel
        isActive={isActive}
        handleChangeActive={handleChangeActive}
        timeTrackerData={details}
        stopPath={stopPath}
      />
    </div>
  );
};
