import { Modal, ButtonGhost } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiLineTranslation } from 'src/components/MultiLineTranslation';

interface ITipsButton {
  widgetKey: 'BillablePercentage' | 'ExternalPercentage' | 'RegistrationAccuracy';
}

const TipsButton = ({ widgetKey }: ITipsButton) => {
  const { t } = useTranslation('frontpage');

  return (
    <Modal>
      <Modal.Trigger as={ButtonGhost} btnIconLeft="lightbulb" data-automation-id="WidgetTipsButton">
        {t('WidgetTipsButton')}
      </Modal.Trigger>
      <Modal.Dialog>
        <Modal.Header
          data-automation-id={`WidgetTipsHeader${widgetKey}`}
          heading={t(`WidgetTipsHeader${widgetKey}`)}
          closeText={t('WidgetTipsModalClose')}
        />
        <Modal.Content data-automation-id={`WidgetTipsContent${widgetKey}`}>
          <MultiLineTranslation translatedContent={t(`WidgetTipsContent${widgetKey}`)} />
        </Modal.Content>
      </Modal.Dialog>
    </Modal>
  );
};

export default TipsButton;
