import { useGetDropdownAPI } from 'src/apis/dropdownAPI';
import { FilterInput } from 'src/components/ui-components/FilterInput';
import { Stack } from 'src/components/ui-components/Stack';
import {
  useGetFilterValuesById,
  useMatchSelectedFilterKey,
  useGetParentQueryString,
} from 'src/stores/FilterStore';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import ResponseHandler from 'src/components/ResponseHandler';
import { IChildInputComponent, IOnChangeInput } from '../../InputContainerTypes';
import { FilterFeedback } from '../FilterFeedback';
import { useOnChangeHandler } from '../helpers';

const SingleDropdown = ({
  contentUrl,
  filterId,
  fieldLabel,
  fieldPlaceholder,
  filterType,
  childFilters = [],
  parentFilters = [],
  activateInputContainerRef,
}: IChildInputComponent<'SingleSelect' | 'SingleSelectTypeSearch'>) => {
  const pageIdentifier = useGetCurrentPageIdentifier();
  const defaultValue = useGetFilterValuesById(fieldLabel, filterType);
  const matchChild = useMatchSelectedFilterKey(childFilters);
  const queryString = useGetParentQueryString(parentFilters);
  const { dropdownList, isError, isLoading } = useGetDropdownAPI({
    key: `filterInput${filterId}`,
    MSWKey: `FILTER_INPUT_${filterId}`,
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}${queryString}` || '',
  });

  const onChangeHandler = useOnChangeHandler({
    contentUrl,
    fieldLabel,
    filterType,
    childFilters,
    activateInputContainerRef,
  });

  return (
    <ResponseHandler isEmpty={dropdownList.length <= 0} isLoading={isLoading} isError={isError}>
      <Stack>
        <FilterInput
          filterId={filterId}
          filterType={filterType}
          fieldLabel={fieldLabel}
          placeholder={fieldPlaceholder}
          data-automation-id={`SingleSelect${filterId}`}
          options={dropdownList}
          addFilterItem={() => {}}
          onChange={onChangeHandler}
          defaultValue={defaultValue as IOnChangeInput}
          autoFocus
          openMenuOnFocus={matchChild.length <= 0}
          tabSelectsValue={false}
        />

        <FilterFeedback matchChildren={matchChild} />
      </Stack>
    </ResponseHandler>
  );
};

export default SingleDropdown;
