import { IconButton, Modal } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiLineTranslation } from 'src/components/MultiLineTranslation';

export const InfoModal = () => {
  const { t } = useTranslation('notificationCenter');
  return (
    <Modal>
      <Modal.Trigger
        as={IconButton}
        iconName="info"
        size="compact"
        tooltipText={t('InfoModalTriggerButtonToolTip')}
        data-automation-id="NotificationCenterInfoModalTriggerButton"
      />
      <Modal.Dialog>
        <Modal.Header
          heading={t('InfoModalHeading')}
          closeText={t('InfoModalCloseButtonToolTip')}
        />
        <Modal.Content>
          <MultiLineTranslation translatedContent={t('InfoModalText')} />
        </Modal.Content>
      </Modal.Dialog>
    </Modal>
  );
};
