import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { convertShortName } from 'src/consts/language';
import { IUserSettings } from 'src/apis/types/userSettingsAPI';

const USER_SETTINGS_URL_PATH = '/api/v2/user-settings';
export const USER_SETTINGS_MSW_STATUS_KEY = 'userSettingsAPI';

export const USER_SETTINGS_KEY = 'USER_SETTINGS';

const getUserSettings = (): Promise<IUserSettings> =>
  getFetch({ path: USER_SETTINGS_URL_PATH, key: USER_SETTINGS_MSW_STATUS_KEY });

const useGetUserSettingsQuery = () =>
  useQuery([USER_SETTINGS_KEY], getUserSettings, { refetchOnMount: false });

export const useGetCurrentLanguage = () => {
  const { data, ...restProps } = useGetUserSettingsQuery();
  const { shortString = 'en', lcid } = data?.properties?.language || {};
  return {
    currentLanguage: data?.properties?.language?.shortString,
    lcid,
    longStringLanguage: convertShortName[shortString],
    ...restProps,
  };
};

export const useGetShowSignUpButton = () => {
  const { data, ...restProps } = useGetUserSettingsQuery();
  return {
    showSignUpButton: data?.properties?.showSignUpButton ?? false,
    ...restProps,
  };
};

export const useGetCustomerSegmentation = () => {
  const { data, ...restProps } = useGetUserSettingsQuery();
  return {
    customerSegmentation: data?.properties?.customerSegmentation,
    ...restProps,
  };
};

export default getUserSettings;
