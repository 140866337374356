import { Trans } from 'react-i18next';
import { Spinner } from 'src/components/ui-components/Spinner';
import { Text } from 'src/components/ui-components/Text';
import VerticalNavigation from 'src/components/VerticalNavigation';
import { IMenusPropertiesWithPagePath, ISubMenusWithPagePath } from 'src/apis/types/menusAPI';
import { escapeSearchText } from 'src/utils/string';
import { IVerticalNavigationSearchBar } from '../../VerticalNavigationSearchBarType';

export interface ISearchResultProps extends IVerticalNavigationSearchBar {
  searchText: string;
  navigationBarRef: React.RefObject<HTMLElement>;
  onItemSelect: () => void;
}

interface ISearchTextProps extends Pick<ISearchResultProps, 'searchText' | 'menu'> {}

const filterSearchingData = ({
  menu,
  searchText,
}: ISearchTextProps): IMenusPropertiesWithPagePath[] | undefined => {
  if (menu) {
    const returnValue: IMenusPropertiesWithPagePath[] = [];
    menu?.forEach(({ subMenus = [], translation, ...restProps }) => {
      const searchRegExp = new RegExp(escapeSearchText(searchText), 'i');
      const subMenuResult: ISubMenusWithPagePath[] =
        subMenus?.filter(({ translation: subTranslation }) => searchRegExp.test(subTranslation)) ||
        [];
      if (subMenuResult.length > 0 || (subMenus.length === 0 && searchRegExp.test(translation)))
        returnValue.push({ ...restProps, subMenus: subMenuResult, translation });
    });
    return returnValue;
  }
  return undefined;
};

export const filterSearchingDataForTest = filterSearchingData;

export const SearchResult = ({
  searchText,
  t,
  menu,
  error,
  isLoading,
  icons,
  navigationBarRef,
  onItemSelect = () => {},
}: ISearchResultProps) => {
  if (isLoading) return <Spinner data-automation-id="SearchResultIsLoading" />;

  if (error)
    return <Text data-automation-id="NavigationErrorText">{t('CouldNotLoadNavigationError')}</Text>;

  const filterData = filterSearchingData({ menu, searchText });

  if (filterData && filterData?.length > 0) {
    return (
      <VerticalNavigation
        data={filterData}
        navigationName={t('HeaderTitle')}
        matchName={searchText}
        icons={icons}
        navigationBarRef={navigationBarRef}
        onItemSelect={onItemSelect}
      />
    );
  }

  return (
    <Text data-automation-id="EmptySearch">
      <Trans
        i18nKey="NoSearchResults"
        key="NoSearchResults"
        defaults={t('NoSearchResults')}
        values={{ searchText }}
        components={{ bold: <strong /> }}
      />
    </Text>
  );
};
