import { designSevenPagePath } from 'src/designSevenPagePath';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'src/components/ui-components';
import PageRoute from 'src/routers/PageRoute';
import ScreenHeader from 'src/components/ScreenHeader';

const LinkComponent = () => (
  <ul>
    <li>
      <Link href="/sample-path/example" data-automation-id="ExampleLink">
        example
      </Link>
    </li>
    <li>
      <Link href="/sample-path/single/123" data-automation-id="Employee123Link">
        single 123
      </Link>
    </li>
    <li>
      <Link href="/sample-path/wrong-path" data-automation-id="NotDesignSevenReadyLink">
        Not Design Seven Ready
      </Link>
    </li>
  </ul>
);

export default () => (
  <Routes>
    <Route
      path={designSevenPagePath.sample.example}
      element={
        <PageRoute pageId="example" pageIdentifier="example" pageTitle="example">
          <>
            <ScreenHeader />
            <LinkComponent />
            <p data-automation-id="ExampleComponent">example</p>
          </>
        </PageRoute>
      }
    />
    <Route
      path={designSevenPagePath.sample.single}
      element={
        <PageRoute pageId="123" pageIdentifier="456" pageTitle="single 123">
          <>
            <ScreenHeader />
            <LinkComponent />
            <p data-automation-id="SingleComponent">123</p>
          </>
        </PageRoute>
      }
    />
    <Route
      path={designSevenPagePath.sample.wrongPath}
      element={
        <PageRoute pageId="345" pageIdentifier="7543" pageTitle="Wrong Path">
          <>
            <LinkComponent />
            <p data-automation-id="WrongPathComponent">Wrong Path</p>
          </>
        </PageRoute>
      }
    />
  </Routes>
);
