import { Avatar } from 'src/components/ui-components/Avatar';
import { Flex } from 'src/components/ui-components/Flex';
import { IconButtonAnchor } from 'src/components/ui-components/IconButton';
import { Text } from 'src/components/ui-components/Text';
import { useGetLocale } from 'src/components/ui-components/LocaleProvider';
import { HTMLAttributes } from 'react';
import { convertDateToLocaleString } from 'src/utils/date';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { formatUrl } from 'src/utils/url';

interface ListItemProps extends HTMLAttributes<HTMLElement> {
  name: string;
  employeeId: number;
  navigationItemType?: number;
  normalWorkingTimeHoursInPeriod?: number;
  hours?: number;
  startDate?: string;
  endDate?: string;
  url?: string;
  linkText?: string;
}

export const ListItem = ({
  name,
  employeeId,
  navigationItemType,
  normalWorkingTimeHoursInPeriod,
  hours,
  startDate,
  endDate,
  url,
  linkText,
  ...props
}: ListItemProps) => {
  const siteLocale = useGetLocale();
  const isAbsence = navigationItemType === 1 && startDate && endDate;
  const isOneDay = startDate === endDate;
  const isTimeSheet = hours !== undefined && normalWorkingTimeHoursInPeriod !== undefined;

  return (
    <Flex horizontalAlignment="justify" verticalAlignment="top" {...props}>
      <Flex verticalAlignment="center">
        <Avatar src={getUserAvatar(employeeId)} loading="lazy" noBorder size="normal" />
        <div>
          <Text>{name}</Text>
          <Flex horizontalAlignment="justify" gap="medium">
            {isTimeSheet && (
              <Text size="tips" semibold data-automation-id="ListItemHours">{`${hours.toFixed(
                2,
              )}/${normalWorkingTimeHoursInPeriod.toFixed(2)}`}</Text>
            )}
            {startDate && endDate && (
              <div>
                {isOneDay && (
                  <Text size="tips" data-automation-id="ListItemAbsenceOneDay">
                    {convertDateToLocaleString(new Date(startDate), siteLocale)}
                  </Text>
                )}
                {!isOneDay && (
                  <Text size="tips" data-automation-id="ListItemAbsenceRange">
                    {convertDateToLocaleString(new Date(startDate), siteLocale)} -{' '}
                    {convertDateToLocaleString(new Date(endDate), siteLocale)}
                  </Text>
                )}
              </div>
            )}
          </Flex>
        </div>
      </Flex>
      {url && linkText && (
        <IconButtonAnchor
          href={formatUrl(
            `${url}${
              // startAbsenceDate is being used in TLP
              isAbsence ? `&startAbsenceDate=${startDate.split('T')[0]}` : ''
            }`,
          )}
          iconName="linked"
          tooltipText={linkText}
          size="compact"
          border
          data-automation-id={
            isAbsence ? 'ApproveSingleAbsenceSheetLink' : 'ApproveSingleTimeSheetLink'
          }
        />
      )}
    </Flex>
  );
};
