import Cookies from 'js-cookie';
import { TEST_OBJECT } from 'src/consts/cookies';
import {
  calculateStartTimeStringInMin,
  getHourAndMinFromTimeTrackerResult,
} from './timeTrackerTime';

// This component is use to test the function where unable to test in cypress unit test.
export default () => {
  const { startTime, timeTrackerProperties, serverTimeZoneId } = JSON.parse(
    Cookies.get(TEST_OBJECT) || '',
  );

  return (
    <>
      <p data-automation-id="TimeTrackerCalculation">
        {calculateStartTimeStringInMin(startTime, serverTimeZoneId)}
      </p>
      <p data-automation-id="TimeTrackerHourAndMin">
        {JSON.stringify(
          getHourAndMinFromTimeTrackerResult(timeTrackerProperties, serverTimeZoneId),
        )}
      </p>
    </>
  );
};
