import { Avatar, Flex, Icon, IconButton, Text, TextLink } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { useDeleteRelationship } from 'src/apis/resourcePlannerAPI';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { Row, RowModel } from '@tanstack/react-table';
import { RPRow, RTRow } from '../../types/resourcePlanner';
import { TaskSelector } from './components/TaskSelector';
import { ResourceSelector } from './components/ResourceSelector';
import { AssignAction } from './components/AssignAction';
import { AssignActionProps, AssignmentSelectorFittedProps } from './types';
import styles from './NameColumnRenderer.module.scss';

interface NameColumnRendererProps {
  row: RTRow;
  value: string;
  handleOnToggleRow: (row: RTRow) => void;
  getRowModel: () => RowModel<RPRow>;
}

const NameColumnRenderer = ({
  row,
  value,
  handleOnToggleRow,
  getRowModel,
}: NameColumnRendererProps) => {
  const { t } = useTranslation('resourcePlanner');
  const { mutate: deleteWorkItemRelationship } = useDeleteRelationship();

  const {
    original: {
      canAssign,
      canDelete,
      hierarchyType,
      workItemId,
      workItemType,
      workItemSourceReferenceId,
      name,
      type,
      resourceId,
      resourceSourceReferenceId,
      profileImage: profileImageLink = resourceSourceReferenceId
        ? getUserAvatar(resourceSourceReferenceId)
        : undefined,
      resourceType,
    },
  } = row;

  let actionProps: AssignmentSelectorFittedProps | undefined;
  let actionDeleteProps: AssignActionProps | undefined;

  const isWorkItem = hierarchyType === 'workitem';

  if (canAssign) {
    actionProps = {
      iconName: isWorkItem ? 'personOutlinePlus' : 'inventoryPlus',
      tooltipText: isWorkItem ? t('SelectAPersonChildren') : t('SelectATaskChildren'),
      heading: isWorkItem ? t('SelectAPersonChildren') : t('SelectATaskChildren'),
      closeText: isWorkItem ? t('SelectAPersonCloseText') : t('SelectAPersonCloseText'),
      itemId: (isWorkItem ? workItemId : resourceId) || '',
      itemSourceReferenceId:
        (isWorkItem ? workItemSourceReferenceId : resourceSourceReferenceId) || '',
      itemType: (isWorkItem ? workItemType : resourceType) || '',
      itemTitle: name,
    };
  }

  if (canDelete) {
    actionDeleteProps = {
      iconName: isWorkItem ? 'inventoryMinus' : 'personOutlineMinus',
      tooltipText: isWorkItem ? t('RemoveTask') : t('RemoveAllocation'),
      heading: isWorkItem ? t('RemoveTask') : t('RemoveAllocation'),
      closeText: isWorkItem ? t('SelectAPersonCloseText') : t('SelectAPersonCloseText'),
      children: isWorkItem ? t('ConfirmRemoveTask') : t('ConfirmRemoveAllocation'),
      onConfirm: () =>
        deleteWorkItemRelationship({
          workItemId,
          workItemSourceReferenceId,
          workItemType,
          resourceId,
          resourceSourceReferenceId,
          resourceType,
        }),
    };
  }

  const onExpandClick = async () => {
    if (handleOnToggleRow) {
      await handleOnToggleRow(row);
      if (
        (row.original.id.indexOf('_') !== -1 && row.original.type !== 'Employee') ||
        (row.original.type !== 'Project' && row.original.id.indexOf('_') === -1)
      ) {
        setTimeout(() => {
          const tableModel = getRowModel();
          let flatRows: Row<RPRow>[] = [];
          if (row.original.id.indexOf('_') !== -1) {
            const resourceIdIndex = 0;
            const idIndex = 1;
            const rowId = row.original.id.split('_')[idIndex];
            const rowResourceId = row.original.id.split('_')[resourceIdIndex];
            flatRows = tableModel.flatRows.filter(
              (flatRow) =>
                flatRow.original.id.includes(rowResourceId) &&
                flatRow.original.pathToParent?.includes(rowId),
            );
          } else {
            flatRows = tableModel.flatRows.filter((flatRow) =>
              flatRow.original.pathToParent?.includes(row.original.id),
            );
          }

          flatRows.forEach(async (flatRow) => {
            if (flatRow.original.canExpand && !flatRow.getIsExpanded()) {
              await handleOnToggleRow(flatRow);
            }
          });
        });
      }
    }
  };

  return (
    <Flex gap="xxSmall">
      {row.getCanExpand() ? (
        <IconButton
          tooltipText={row.getIsExpanded() ? 'Collapse' : 'Expand'}
          iconSize="compact"
          iconName={row.getIsExpanded() ? 'pointDown' : 'pointRight'}
          onClick={onExpandClick}
        />
      ) : (
        <span className={styles.spacer} />
      )}
      {type === 'Project' && <Icon iconName="project" />}
      {type === 'Task' && <Icon iconName="taskAlt" />}

      {type === 'Employee' && (
        <div className={styles.avatar}>
          {profileImageLink ? (
            <Avatar src={profileImageLink} loading="lazy" noBorder size="normal" />
          ) : (
            <Icon iconName="darkProfile" size="normal" />
          )}
        </div>
      )}
      <div className={styles.name}>
        {type === 'Project' && (
          <TextLink
            href={`/ProjectManagement/Plan/Task/${row.original.workItemSourceReferenceId}`}
            title={t('ProjectLinkTooltip')}
            useTextColor
          >
            {value}
          </TextLink>
        )}
        {type === 'Task' && <span>{value}</span>}
        {type === 'Employee' && (
          <>
            <Text inheritSize>{value}</Text>
            {row.original.role ? <Text size="subtips">{row.original.role}</Text> : null}
          </>
        )}
      </div>

      <Flex.Item fillRest> </Flex.Item>
      {actionProps &&
        (isWorkItem ? <ResourceSelector {...actionProps} /> : <TaskSelector {...actionProps} />)}
      {actionDeleteProps && <AssignAction {...actionDeleteProps} />}
    </Flex>
  );
};

export default NameColumnRenderer;
