import { IContextMenuModal } from 'src/components/ui-components/ContextMenu/ContextMenuType';
import MileageRegistration from 'src/screens/PageActionsModal/MileageRegistration';

export default {
  wrongId: {
    childComponent: (
      <p data-automation-id="wrongId">
        Please setup your modal id in consts/screenHeaderModalItem.tsx
      </p>
    ),
  },
  32: {
    childComponent: <MileageRegistration />,
  },
} as Record<string, Pick<IContextMenuModal, 'childComponent' | 'headingComponent'>>;
