import { htmlToProsemirrorNode, prosemirrorNodeToHtml, EditorState } from 'remirror';
import { EditorComponent, Remirror, useKeymap, useRemirror } from '@remirror/react';
import {
  HistoryExtension,
  BoldExtension,
  ItalicExtension,
  CalloutExtension,
  UnderlineExtension,
  StrikeExtension,
  HeadingExtension,
  ImageExtension,
  DropCursorExtension,
  HorizontalRuleExtension,
  OrderedListExtension,
  BulletListExtension,
  ListItemExtension,
  HardBreakExtension,
  NodeFormattingExtension,
  LinkExtension,
  CodeBlockExtension,
  TrailingNodeExtension,
  BlockquoteExtension,
} from 'remirror/extensions';
import { ButtonGhost, ButtonPrimary } from 'src/components/ui-components/Button';
import { Flex } from 'src/components/ui-components/Flex';
import { Stack } from 'src/components/ui-components/Stack';
import { Tooltip } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { HTMLAttributes } from 'react';
import { Menu } from './components';

import sharedRichTextStyles from '../RichText.module.scss';
import styles from './RichTextEditor.module.scss';
import { useBuildShortcutString } from './components/Menu/helpers';

interface SharedProps {
  saveFunction: (html: string) => void;
  exitFunction: () => void;
}

interface HooksProps extends SharedProps {
  state: Readonly<EditorState>;
}

const hooks = ({ state, saveFunction, exitFunction }: HooksProps) => [
  () => {
    const handleSaveShortcut = () => {
      saveFunction(prosemirrorNodeToHtml(state.doc));
      exitFunction();
      return true; // Prevents any further key handlers from being run.
    };

    const handleEscapeShortcut = () => {
      exitFunction();
      return true;
    };

    // "Mod" means platform agnostic modifier key - i.e. Ctrl on Windows, or Cmd on MacOS
    useKeymap('Mod-s', handleSaveShortcut);
    useKeymap('Escape', handleEscapeShortcut);
  },
];

const extensions = () => [
  new HistoryExtension(),
  new LinkExtension({ autoLink: true }),
  new HardBreakExtension(),
  new HeadingExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new UnderlineExtension(),
  new StrikeExtension(),
  new HorizontalRuleExtension(),
  new OrderedListExtension(),
  new BulletListExtension(),
  new ListItemExtension(),
  new ImageExtension({
    enableResizing: true,
    extraAttributes: { loading: 'lazy' },
  }),
  new CalloutExtension({ defaultType: 'warn' }),
  new CodeBlockExtension(),
  new TrailingNodeExtension(),
  new NodeFormattingExtension(),
  new DropCursorExtension(),
  new BlockquoteExtension(),
];

interface RichTextEditorProps extends SharedProps, HTMLAttributes<HTMLElement> {
  value: string;
}

export const RichTextEditor = ({
  value,
  saveFunction,
  exitFunction,
  ...props
}: RichTextEditorProps): JSX.Element => {
  const { manager, state, onChange } = useRemirror({
    selection: 'start',
    extensions,
    content: value,
    stringHandler: htmlToProsemirrorNode,
  });

  const { t } = useTranslation('richTextEditor');

  const shortcutString = useBuildShortcutString();

  return (
    <Stack verticalMargin="xSmall" {...props}>
      <div className={styles.Wrapper}>
        <Remirror
          manager={manager}
          autoFocus
          onChange={onChange}
          initialContent={state}
          hooks={hooks({ state, saveFunction, exitFunction })}
        >
          <>
            <Menu className={styles.Menu} />
            <div className={sharedRichTextStyles.RichText}>
              <EditorComponent data-automation-id="RichTextEditorComponent" />
            </div>
          </>
        </Remirror>
      </div>
      <Flex horizontalAlignment="right" gap="small">
        <Tooltip title={`${t('CancelEditorChangesLabel')} (Esc)`}>
          <ButtonGhost data-automation-id="btngExitEditor" onClick={exitFunction}>
            {t('CancelEditorChangesLabel')}
          </ButtonGhost>
        </Tooltip>
        <Tooltip title={shortcutString(t('SaveEditorChangesLabel'), 'S')}>
          <ButtonPrimary
            data-automation-id="btnpSaveAndExitEditor"
            onClick={() => {
              saveFunction(prosemirrorNodeToHtml(state.doc));
              exitFunction();
            }}
          >
            {t('SaveEditorChangesLabel')}
          </ButtonPrimary>
        </Tooltip>
      </Flex>
    </Stack>
  );
};
