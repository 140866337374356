import { Avatar, Flex, Text } from 'src/components/ui-components';
import HighlightMatchingText from 'src/components/HighlightMatchingText';
import { getUserAvatar } from 'src/utils/getUserAvatar';

interface AssignItemProps {
  mainText: string;
  id?: string;
  secondaryText?: string;
  tertiaryText?: string;
  type?: string;
  searchText?: string;
}

export default ({
  id,
  mainText,
  secondaryText,
  tertiaryText,
  type,
  searchText,
}: AssignItemProps) => {
  const showImage = type === 'Employee' && id;

  return (
    <Flex as="span">
      {showImage && (
        <Flex.Item as="span">
          <Avatar src={getUserAvatar(id)} size="xLarge" />
        </Flex.Item>
      )}
      <Flex.Item as="span" fillRest>
        <Text as="span" fullWidth>
          <HighlightMatchingText name={mainText} matchName={searchText} />
        </Text>
        {secondaryText && (
          <Text as="span" fullWidth dimmed>
            <HighlightMatchingText name={secondaryText} matchName={searchText} />
          </Text>
        )}
        {tertiaryText && (
          <Text as="span" fullWidth dimmed>
            <HighlightMatchingText name={tertiaryText} matchName={searchText} />
          </Text>
        )}
      </Flex.Item>
    </Flex>
  );
};
