import { useGetLocale } from 'src/components/ui-components/LocaleProvider';
import { convertDateToLocaleString } from 'src/utils/date';
import { HTMLAttributes } from 'react';

interface DateTextProps extends HTMLAttributes<HTMLSpanElement> {
  value: Date;
  dataAutomationId?: string;
}

export const DateText = ({ value, className, style, ...restProps }: DateTextProps) => {
  const locale = useGetLocale();

  if (value == null) {
    return null;
  }

  const dateString = convertDateToLocaleString(value, locale);

  return <span {...restProps}>{dateString}</span>;
};
