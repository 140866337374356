import { CardContent, CardHeader, Card, MuiIconButton } from '@timelog/ui-library';
import { Fragment, ReactNode, useState } from 'react';
import { Info /* , MoreVert */ } from '@mui/icons-material';
import ReportingWidgetInfoModal from 'src/screens/Home/components/ReportingWidgetInfoModal';

interface IReportingWidgetCard {
  title: string;
  name: string;
  infoIconText: string;
  children?: ReactNode;
  hasAction?: boolean;
}

export const ReportingWidgetCard = ({
  name,
  title,
  infoIconText,
  children,
  hasAction = false,
}: IReportingWidgetCard) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card className={`Card-${title}`} variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={title}
        action={
          hasAction && (
            <>
              <Fragment key={`InfoIconButton${name}`}>
                <MuiIconButton
                  size="small"
                  aria-label={`info-icon-button-${name}`}
                  data-automation-id={`InfoIconButton${name}`}
                  onClick={() => setIsOpen(true)}
                >
                  <Info />
                </MuiIconButton>
                <ReportingWidgetInfoModal
                  id={name}
                  title={title}
                  infoMessage={infoIconText}
                  openState={isOpen}
                  setOpenState={setIsOpen}
                />
              </Fragment>
              {/* 
              // DRO: Will leave the action here for a while since we are going to have this in the next iteration.
              <MuiIconButton size="small" aria-label="action">
              <MoreVert />
            </MuiIconButton> */}
            </>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ReportingWidgetCard;
