import { FilterInput } from 'src/components/ui-components/FilterInput';
import { Flex } from 'src/components/ui-components/Flex';
import { RadioButton } from 'src/components/ui-components/RadioButton';
import { Stack } from 'src/components/ui-components/Stack';
import { useTranslation } from 'react-i18next';
import {
  useGetFilterValuesById,
  useMatchSelectedFilterKey,
  useGetParentQueryString,
  useFilterStore,
  useFilterDispatch,
} from 'src/stores/FilterStore';
import ResponseHandler from 'src/components/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { ChangeEvent, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import HighlightMatchingText from 'src/components/HighlightMatchingText';
import { useOnChangeHandler } from '../helpers';
import { FilterFeedback } from '../FilterFeedback';
import { IChildInputComponent, IOnChangeInput } from '../../InputContainerTypes';

const MultiSelectDropdown = ({
  contentUrl,
  filterId,
  fieldLabel,
  fieldPlaceholder,
  filterType,
  childFilters = [],
  parentFilters = [],
  activateInputContainerRef,
}: IChildInputComponent<'MultiSelect' | 'MultiSelectTypeSearch'>) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const dispatch = useFilterDispatch();
  const { selectedFilterList } = useFilterStore();
  const { t } = useTranslation('filter');

  const [isInclude, setIsInclude] = useState<string>(
    selectedFilterList[fieldLabel]?.isInclude === false ? 'is-not' : 'is',
  );

  const pageIdentifier = useGetCurrentPageIdentifier();
  const defaultValues = useGetFilterValuesById(fieldLabel, filterType);
  const matchChild = useMatchSelectedFilterKey(childFilters);
  const queryString = useGetParentQueryString(parentFilters);

  const { dropdownList, isError, isFetching, isLoading } = useGetDropdownWithSearchAPI({
    key: `filterInput${filterId}`,
    MSWKey: `FILTER_INPUT_${filterId}`,
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}${queryString}` || '',
    searchText: debouncedInputValue,
  });

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const onChangeHandler = useOnChangeHandler({
    contentUrl,
    fieldLabel,
    filterType,
    childFilters,
    parentFilters,
    activateInputContainerRef,
    isInclude: isInclude === 'is',
  });

  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInclude(e.target.value);

    if (dispatch && selectedFilterList[fieldLabel]) {
      dispatch({
        type: 'ADD_OR_UPDATE_FILTER',
        payload: {
          [fieldLabel]: {
            label: fieldLabel,
            values: selectedFilterList[fieldLabel].values.map((filter) => ({
              label: filter.label,
              value: filter.value,
            })),
            filterType,
            contentUrl,
            childFilters,
            parentFilters,
            isInclude: isInclude === 'is-not',
          },
        },
      });

      matchChild?.forEach((filterID) => {
        dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
      });
    }
  };

  interface HighlightTextProps {
    label: string;
  }

  const formatOptionLabel = ({ label }: HighlightTextProps) => (
    <HighlightMatchingText name={label} matchName={inputValue} />
  );

  return (
    <ResponseHandler isError={isError}>
      <Stack>
        <Stack verticalMargin="small">
          <Flex gap="large">
            <RadioButton
              label={t('DropDownRadioIsLabel')}
              name={`IsOrIsNot${filterId}Radio`}
              value="is"
              checked={isInclude === 'is'}
              onChange={(e) => {
                setIsInclude('is');
                onRadioChange(e);
              }}
            />
            <RadioButton
              label={t('DropDownRadioIsNotLabel')}
              name={`IsOrIsNot${filterId}Radio`}
              value="is-not"
              checked={isInclude === 'is-not'}
              onChange={(e) => {
                setIsInclude('is-not');
                onRadioChange(e);
              }}
            />
          </Flex>

          <FilterInput
            filterId={filterId}
            filterType={filterType}
            fieldLabel={fieldLabel}
            placeholder={fieldPlaceholder}
            addFilterItem={() => {}}
            options={dropdownList}
            onChange={onChangeHandler}
            data-automation-id={`MultiSelect${filterId}`}
            defaultValue={defaultValues as Array<IOnChangeInput>}
            openMenuOnFocus={matchChild.length <= 0}
            autoFocus
            tabSelectsValue={false}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            isLoading={isFetching || isLoading}
            formatOptionLabel={formatOptionLabel}
            closeMenuOnSelect={false}
          />
        </Stack>

        <FilterFeedback matchChildren={matchChild} />
      </Stack>
    </ResponseHandler>
  );
};

export default MultiSelectDropdown;
