import { FC, ReactNode } from 'react';
import { MuiStack } from '@timelog/ui-library';
import styles from '../ExchangeRates.module.scss';

interface SystemAdminContentWrapperProps {
  header: string;
  description?: string;
  children?: ReactNode;
}

const SystemAdminContentWrapper: FC<SystemAdminContentWrapperProps> = ({
  header,
  description,
  children,
}) => (
  <div className={styles.systemAdminContentWrapper}>
    <MuiStack direction="column" spacing={4}>
      <div className="text-container">
        <h2>{header}</h2>
        {description && <h3>{description}</h3>}
      </div>
      <div>{children}</div>
    </MuiStack>
  </div>
);

export default SystemAdminContentWrapper;
