import { MuiGrid } from '@timelog/ui-library';
import ReportingWidgetControl from './components/ReportingWidgetControl';
import InvoicedPerMonthReportingWidget from '../InvoicedPerMonthReportingWidget';
import OverdueInvoicesReportingWidget from '../OverdueInvoicesReportingWidget';
import ProjectEconomyHealthReportingWidget from '../ProjectEconomyHealthReportingWidget';
import PlannedAbsenceReportingWidget from '../PlannedAbsenceReportingWidget';
import AverageHourlyRateReportingWidget from '../AverageHourlyRateReportingWidget';

export const ReportingWidgetContainer = () => (
  <div data-automation-id="ReportWidgetContainer">
    <MuiGrid container>
      <ReportingWidgetControl widgetTypeName="InvoicedPerMonth">
        <MuiGrid item xs={12} md={12} lg={12}>
          <InvoicedPerMonthReportingWidget />
        </MuiGrid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="AverageHourlyRate">
        <MuiGrid item xs={12} md={12} lg={12}>
          <AverageHourlyRateReportingWidget />
        </MuiGrid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="PlannedAbsence">
        <MuiGrid item xs={12} md={12} lg={12}>
          <PlannedAbsenceReportingWidget />
        </MuiGrid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="ProjectEconomyHealth">
        <MuiGrid item xs={12} md={12} lg={12}>
          <ProjectEconomyHealthReportingWidget />
        </MuiGrid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="OverdueInvoices">
        <MuiGrid item xs={12} md={12} lg={12}>
          <OverdueInvoicesReportingWidget />
        </MuiGrid>
      </ReportingWidgetControl>
    </MuiGrid>
  </div>
);

export default ReportingWidgetContainer;
