import { useGetLocale } from 'src/components/ui-components/LocaleProvider';
import { formatTime } from 'src/utils/time';

interface EditableCellProps {
  displayValue: number;
  unitType?: string;
}

const TotalColumnRenderer = ({ displayValue, unitType }: EditableCellProps) => {
  const siteLocale = useGetLocale();

  return `${formatTime(displayValue || '0', siteLocale)}${
    unitType && unitType === 'percentages' ? ' %' : ''
  }`;
};

export default TotalColumnRenderer;
