import { BarChartComponent, BarChartProps } from 'src/components/ui-components';
import ReportingWidgetCard from 'src/components/ReportingWidgetCard/ReportingWidgetCard';
import Loading from 'src/components/MilestoneFeed/components/Loading/Loading';
import Error from 'src/components/MilestoneFeed/components/Error/Error';

interface IReportingWidgetWithChart {
  name: string;
  title: string;
  data: {}[];
  infoIconText: string;
  xAxisDataKey: string;
  yAxisDataKey: string;
  yAxisConfigs?: object[];
  toolTipConfigs?: object;
  barPropsDataKey: string[];
  isEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
}

const parseBarPropsData = (dataKeyList: string[]) => {
  const barProps = dataKeyList.map((key) => ({
    dataKey: key,
  }));
  return barProps;
};

const ReportBarChart = ({
  xAxisKey,
  yAxisKey,
  yAxisConfigs,
  tooltipConfigs,
  data,
  barProps,
}: BarChartProps) => (
  // not dynamic-ready atm. Need to define dataKey for each bar
  <div style={{ width: '100%', height: 300 }}>
    <BarChartComponent
      data={data}
      surfaceMargin={{ top: 20, right: 20, bottom: 20, left: 32 }}
      barProps={barProps}
      tooltip
      tooltipConfigs={tooltipConfigs}
      cartesianGrid
      legend
      xAxisKey={xAxisKey}
      yAxisKey={yAxisKey}
      yAxisConfigs={yAxisConfigs}
    />
  </div>
);
export const ReportingWidgetWithChart = ({
  name,
  title,
  data,
  infoIconText,
  xAxisDataKey,
  yAxisDataKey,
  yAxisConfigs,
  toolTipConfigs,
  barPropsDataKey,
  isEmpty,
  isError,
  isLoading,
}: IReportingWidgetWithChart) => {
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  return (
    <ReportingWidgetCard name={name} title={title} infoIconText={infoIconText} hasAction>
      {!isEmpty && (
        <div data-automation-id={`ReportingWidgetBarChart-${name}`}>
          <ReportBarChart
            xAxisKey={xAxisDataKey}
            yAxisKey={yAxisDataKey}
            yAxisConfigs={yAxisConfigs || []}
            data={data}
            tooltipConfigs={toolTipConfigs}
            barProps={parseBarPropsData(barPropsDataKey)} // {[{ dataKey: '2022' }, { dataKey: '2023' }]}
          />
        </div>
      )}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithChart;
