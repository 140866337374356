import { useState } from 'react';
import { ButtonGhost, IconButton, Modal } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { AssignActionProps } from '../../types';

export const AssignAction = ({
  iconName,
  tooltipText,
  heading,
  closeText,
  isOpen,
  setIsOpen,
  onDismiss,
  children,
  footerComponent,
  onConfirm,
  initialFocusRef,
}: AssignActionProps) => {
  const { t } = useTranslation('resourcePlanner');
  const [isOpenLocal, setIsOpenLocal] = useState(false);
  const open = () => (setIsOpen ? setIsOpen(true) : setIsOpenLocal(true));
  const close = () => (setIsOpen ? setIsOpen(false) : setIsOpenLocal(false));
  const onConfirmWithClose = () => {
    if (onConfirm) onConfirm();
    close();
  };
  const localOnDismiss = onDismiss || close;

  return (
    <Modal>
      <Modal.Trigger
        as={IconButton}
        iconName={iconName}
        tooltipText={tooltipText}
        size="compact"
        onClick={open}
      />
      <Modal.Dialog
        size="small"
        initialFocusRef={initialFocusRef}
        isOpen={isOpen || isOpenLocal}
        onDismiss={localOnDismiss}
      >
        <Modal.Header heading={heading} closeText={closeText} closeModal={localOnDismiss} />
        <Modal.Content>{children}</Modal.Content>
        {footerComponent && <Modal.Footer>{footerComponent}</Modal.Footer>}
        {onConfirm && (
          <Modal.Footer>
            <ButtonGhost size="compact" onClick={close}>
              {t('Cancel')}
            </ButtonGhost>
            <ButtonGhost size="compact" onClick={onConfirmWithClose}>
              {t('Ok').toUpperCase()}
            </ButtonGhost>
          </Modal.Footer>
        )}
      </Modal.Dialog>
    </Modal>
  );
};
