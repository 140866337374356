import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@timelog/ui-library';
import { useDeletePeriod } from 'src/apis/exchangeRateAPI';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../utils/serverResponseHandler';

interface DeletePeriodModalProps {
  periodId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPeriodId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const DeletePeriodModal: React.FC<DeletePeriodModalProps> = ({
  periodId,
  isOpen,
  setIsOpen,
  setSelectedPeriodId,
}) => {
  const { mutateAsync: deletePeriod } = useDeletePeriod();
  const { t } = useTranslation('systemAdminExchangeRate');

  const deleteAction = async () => {
    try {
      const response = await deletePeriod(periodId, {
        onError: (error: any) => {
          handleError(error, t);
        },
      });
      if (response) {
        setSelectedPeriodId(undefined);
        return response;
      }
    } catch (error) {
      // no actions required, error handled
    } finally {
      setIsOpen(false);
    }
    return null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-delete-period"
      aria-describedby="dialog-delete-period-desc"
    >
      <DialogTitle id="dialog-delete-period">Delete exchange period</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-delete-period-desc">
          {t('modals.DeletePeriodDescription')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => deleteAction()}
          autoFocus
          data-automation-id="DeletePeriodModalSaveButton"
        >
          {t('modals.DeletePeriodSaveButton')}
        </Button>
        <Button data-automation-id="DeletePeriodModalCancelButton" onClick={() => setIsOpen(false)}>
          {t('modals.CancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePeriodModal;
